export const slideData = [
  {
    id: 1,
    img: "/img/1.jpg",
    title: "Morning Dawn",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit explicabo quis est ad beatae eius.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "22k",
  },
  {
    id: 2,
    img: "/img/2.jpg",
    title: "Light Uo",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "20k",
  },
  {
    id: 3,
    img: "/img/3.jpg",
    title: "The call of the ancient",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit explicabo quis est ad beatae eius.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "25k",
  },
  {
    id: 4,
    img: "/img/4.jpg",
    title: "The Beholding",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "250k",
  },
  {
    id: 5,
    img: "/img/5.jpg",
    title: "Becoming",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "20k",
  },
  {
    id: 6,
    img: "/img/6.jpg",
    title: "Eternal light",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "50k",
  },
  {
    id: 7,
    img: "/img/7.jpg",
    title: "Thy will be done",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "84k",
  },
  {
    id: 8,
    img: "/img/8.jpg",
    title: "The Kingdom is here",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "20k",
  },
  {
    id: 9,
    img: "/img/9.jpg",
    title: "We behold and we become",
    info: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Error, accusamus.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "250k",
  },
  {
    id: 10,
    img: "/img/10.jpg",
    title: "All there is",
    info: "dolor sit amet consectetur adipisicing elit. Magni, tempore.",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "200k",
  },
  {
    id: 11,
    img: "/img/11.jpg",
    title: "There is more",
    info: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Officia illo incidunt dicta odit repellat quisquam nemo soluta assumenda at error, cupiditate similique laborum commodi odio?",
    hash: "#prayer, #worship, #beholding, #becoming, #prayer, #prayerchain",
    viewCount: "250k",
  },
];

export const newsData = [
  {
    id: 1,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 2,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 3,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 4,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 5,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 6,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 7,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 8,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 9,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 10,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
  {
    id: 11,
    img: "/img/10.jpg",
    title: "Believer across 36 states",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta quas, laboriosam libero rerum laborum illum? Rerum illum consequuntur hic ullam debitis est. Architecto, quos fugiat? Commodi, cupiditate. Nam, hic architecto?",
    time: "20 minutes ago",
  },
];
